import { Box, HStack, Image, VStack, Text, Heading, Stack } from "@chakra-ui/react"
import { memo } from "react"
import Avocado from "@static/images/avocado.png"
import { CustomIcon } from "@components/Icon"

type RewardsTierPointsTilesProps = {
  points: number
  tier: string
  tierTitle?: string
  pointsTitle?: string
}

type TileProps = {
  icon: React.ReactNode
  title?: string
  text: string | number
}

const Tile: React.FC<TileProps> = ({ icon, title, text }) => (
  <HStack bg="brand.lightSand" w={["full", "full", "half"]} py={7} pr={16} pl={7} spacing={7}>
    <Box>{icon}</Box>
    <VStack alignItems="flex-start" w="full" spacing={2}>
      <Text fontSize={["sm", "md"]} borderBottom="1px" w="full" pb={2}>
        {title}
      </Text>
      <Heading as="p" fontSize="xl">
        {text || "-"}
      </Heading>
    </VStack>
  </HStack>
)

const RewardsTierPointsTiles: React.FC<RewardsTierPointsTilesProps> = ({ points, tier, tierTitle, pointsTitle }) => {
  return (
    <Stack direction={["column", "column", "row"]} spacing={4} mb={16} alignItems="stretch">
      <Tile icon={<Image src={Avocado} w={14} />} title={tierTitle} text={`GoodnessMe ${tier}`} />
      <Tile icon={<CustomIcon name="account-points" width={56} height={56} />} title={pointsTitle} text={points} />
    </Stack>
  )
}

const MemoRewardsTierPointsTiles = memo(RewardsTierPointsTiles)
export { MemoRewardsTierPointsTiles as RewardsTierPointsTiles }
