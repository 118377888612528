import { memo, useEffect, useState, useCallback } from "react"
import { useRewardsContext } from "@app/providers/rewards"
import { useCore } from "@app/hooks/useCore"
import { useRewards } from "@app/hooks/useRewards"

// See https://developers.loyaltylion.com/sdk/components/rewards-list

type RewardsListProps = {
  points?: number
}

const RewardsList: React.FC<RewardsListProps> = ({ points }) => {
  const [hasAddedProgressBars, setHasAddedProgressBars] = useState(false)
  const [voucherCardsRendered, setVoucherCardsRendered] = useState(false)
  const { loyaltyLionGlobalReady } = useRewardsContext()
  const { refreshRewardsUI } = useRewards()
  const {
    helpers: { isBrowser },
  } = useCore()

  const waitForVoucherCardsToRender = () => {
    const elementToObserve = document.querySelector("[data-lion-rewards-list]")

    const observer = new MutationObserver(mutationList => {
      mutationList.find(mutation => {
        if (mutation?.type === "childList") {
          setVoucherCardsRendered(true)
        }
      })
    })

    if (elementToObserve) {
      observer.observe(elementToObserve, { subtree: true, childList: true })
    }
  }

  const createProgressBar = useCallback(
    (el: any) => {
      // el.style.position = "relative"
      const cardPointsCostEl = el?.querySelector(".lion-reward-item__cost .value")
      const cardPointsCost = cardPointsCostEl ? Number(cardPointsCostEl?.innerText) : 0
      const percentage = (points / cardPointsCost) * 100

      const barContainerDiv = document.createElement("div")
      barContainerDiv.id = "custom-loyaltylion-progressbar-container"

      const progressBarDiv = document.createElement("div")
      progressBarDiv.id = "custom-loyaltylion-progressbar"
      progressBarDiv.style.width = `${percentage}%`

      if (cardPointsCostEl) {
        el?.prepend(barContainerDiv)
        el?.prepend(progressBarDiv)
      }
    },
    [points]
  )

  useEffect(() => {
    if (voucherCardsRendered && !hasAddedProgressBars && points) {
      // timeout is to avoid the added element getting reset by the librarys default refresh which
      // removes the element
      setTimeout(() => {
        const allRewardEls = document?.querySelectorAll(".lion-reward-item__content")
        if (allRewardEls?.length) {
          allRewardEls?.forEach(createProgressBar)
          setHasAddedProgressBars(true)
        }
      }, 1500)
    }
  }, [voucherCardsRendered, hasAddedProgressBars, points, createProgressBar])

  useEffect(() => {
    if (!hasAddedProgressBars && points && isBrowser) {
      waitForVoucherCardsToRender()
    }
  }, [points, hasAddedProgressBars, loyaltyLionGlobalReady, isBrowser])

  useEffect(() => {
    refreshRewardsUI()
  }, [refreshRewardsUI])

  return <div data-lion-rewards-list></div>
}

const MemoRewardsList = memo(RewardsList)
export { MemoRewardsList as RewardsList }
