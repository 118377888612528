import { graphql, PageProps } from "gatsby"
import Page from "@components/Rewards"

export type Props = PageProps<GatsbyTypes.PageAccountRewardsQuery, GatsbyTypes.PageAccountRewardsQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageAccountRewards {
    page: sanityPageAccountRewards {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
      additionalRewardsAndOffers
      additionalRedeemVouchers
      additionalCustomersCurrentTier
      additionalCustomersAvailablePoints
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
