import { memo } from "react"
import { HStack, Button, Heading, Hide, Show } from "@chakra-ui/react"
import { useCustomerLogout } from "@app/hooks/useCustomer"

type CustomerTitleBarProps = {
  title?: string
}

const CustomerTitleBar: React.FC<CustomerTitleBarProps> = ({ title }) => {
  const { logoutCustomer } = useCustomerLogout()

  return (
    <HStack justifyContent="space-between" spacing={6}>
      <Heading as="h2" size="h4">
        {title}
      </Heading>
      <Hide below="sm">
        <Button py="8px" px="46px" variant="outline" mt={4} onClick={logoutCustomer}>
          {/* TODO: add this to language settings */}
          Sign Out
        </Button>
      </Hide>
      <Show below="sm">
        <Button py="8px" px="46px" m={0} variant="link" onClick={logoutCustomer} border="none" color="brand.avocado">
          {/* TODO: add this to language settings */}
          Sign Out
        </Button>
      </Show>
    </HStack>
  )
}

const MemoCustomertitleBar = memo(CustomerTitleBar)
export { MemoCustomertitleBar as CustomerTitleBar }
