import { CustomerPageLayout } from "@components/Customer/CustomerPageLayout"
import { RewardsList } from "@components/Rewards"
import { Heading } from "@chakra-ui/react"
import { RewardsTierPointsTiles } from "./RewardsTierPointsTiles"
// import { RewardsMerchantsAvailableRewards } from "./RewardsMerchantsAvailableRewards"

import type { Props } from "@app/pages/account/rewards"
import type { PageProps } from "@root/types/global"
import { useRewards } from "@app/hooks/useRewards"

type RewardsSectionTitleProps = {
  children: string | React.ReactNode
}

const RewardsSectionTitle: React.FC<RewardsSectionTitleProps> = ({ children }) => (
  <Heading as="p" fontSize={["32px", "30px"]} mb={10}>
    {children}
  </Heading>
)

const Rewards: React.FC<PageProps<Props>> = ({ location, page }) => {
  const { loyaltyTierName, customerPoints } = useRewards()

  return (
    <CustomerPageLayout title="My Account" subtitle={page?.title} location={location}>
      <RewardsTierPointsTiles
        points={customerPoints}
        tier={loyaltyTierName}
        tierTitle={page?.additionalCustomersCurrentTier}
        pointsTitle={page?.additionalCustomersAvailablePoints}
      />

      {/* <RewardsSectionTitle>{page?.additionalRewardsAndOffers}</RewardsSectionTitle>
      <RewardsMerchantsAvailableRewards /> */}

      <RewardsSectionTitle>{page?.additionalRedeemVouchers}</RewardsSectionTitle>
      <RewardsList points={customerPoints} />
    </CustomerPageLayout>
  )
}

export default Rewards
