import { lazy, memo, Suspense } from "react"
import { useCore } from "@app/hooks/useCore"
import { useMedia } from "@app/hooks/useMedia"

import type { Location } from "@root/types/global"

type Props = {
  location: Location
}

const NavigationAccount: React.FC<Props> = ({ location }) => {
  const {
    helpers: { ErrorBoundary },
  } = useCore()
  const { isBase } = useMedia()

  const NavigationAccountDesktop = lazy(() => import("./NavigationAccountDesktop"))
  const NavigationAccountMobile = lazy(() => import("./NavigationAccountMobile"))

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>{isBase ? <NavigationAccountMobile location={location} /> : <NavigationAccountDesktop />}</Suspense>
    </ErrorBoundary>
  )
}

const MemoNavigationAccount = memo(NavigationAccount)
export { MemoNavigationAccount as NavigationAccount }
