import { memo } from "react"
import { Box, HStack, VStack, Text, Image } from "@chakra-ui/react"
import FoodBox from "@static/images/food-box.png"
import { useCustomerContext } from "@app/providers/customer"
import { useCore } from "@app/hooks/useCore"
import { useRewards } from "@app/hooks/useRewards"

const RewardsCustomerBanner = () => {
  const { customer } = useCustomerContext()
  const {
    helpers: { capitalise },
  } = useCore()
  const { loyaltyTierName, customerPoints } = useRewards()
  const customerName = customer?.firstName ? `, ${capitalise(customer?.firstName)}` : ""

  return (
    <HStack mt={[6, 8]} bg="brand.lavender" justifyContent="space-between" maxH={[28, 44]} overflow="hidden">
      <VStack py={[4.5, 4.5, 9]} pl={[5, 5, 12]} alignItems="flex-start" spacing={1}>
        <Text fontWeight={[400, 700]} fontSize={["md", "xl"]}>
          Welcome{customerName}
        </Text>
        <Text fontSize={["md", "xl"]} fontWeight={[700, 400]}>
          You&rsquo;re a GoodnessMe {loyaltyTierName}!
        </Text>
        <Text fontSize={["md", "xl"]} display={["none", "none", "block"]}>
          You have {customerPoints} points
        </Text>
      </VStack>
      <Box>
        <Image src={FoodBox} w={[34, 60]} mr={[1, 1, 12]} />
      </Box>
    </HStack>
  )
}

const MemoRewardsCustomerBanner = memo(RewardsCustomerBanner)
export { MemoRewardsCustomerBanner as RewardsCustomerBanner }
