import { memo } from "react"
import { Box, Container, Flex, Heading, Hide, Show } from "@chakra-ui/react"
import { CustomerTitleBar } from "./CustomerTitleBar"
import { NavigationAccount } from "@components/Navigation/Account/NavigationAccount"
import { useContent } from "@app/hooks/useContent"
import { RewardsCustomerBanner } from "@components/Rewards/RewardsCustomerBanner"

type CustomerPageLayoutProps = {
  content?: any
  location: any
  title?: string
  subtitle?: string
  children?: React.ReactNode
  showRewardsBanner?: boolean
}

const CustomerPageLayout: React.FC<CustomerPageLayoutProps> = ({
  content: rawContent,
  subtitle,
  location,
  title,
  children,
  showRewardsBanner = false,
}) => {
  const { getContent } = useContent()
  const content = getContent({ content: rawContent })

  return (
    <Container pt={[16, 16, 27]} pb={22}>
      <Flex flexDirection={["column", "row"]} gap={0}>
        <Box w={["100%", 66]} pr={[0, 8]}>
          <Hide below="sm">
            <Heading as="h1" size="h4" mb={9}>
              {title}
            </Heading>
          </Hide>
          <Show below="sm">
            <Box pb={6}>
              <CustomerTitleBar title={subtitle} />
            </Box>
          </Show>
          <NavigationAccount location={location} />
        </Box>
        <Box w={["100%", "70%"]} pl={[0, 8]}>
          <Hide below="sm">
            <CustomerTitleBar title={subtitle} />
          </Hide>
          {showRewardsBanner && <RewardsCustomerBanner />}
          <Box my={6} sx={{ p: { fontSize: "sm", lineHeight: "21px" } }}>
            {content}
          </Box>
          {children}
        </Box>
      </Flex>
    </Container>
  )
}

const MemoCustomerPageLayout = memo(CustomerPageLayout)
export { MemoCustomerPageLayout as CustomerPageLayout }
